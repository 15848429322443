import Dropdown from '@theme/components/utils/Dropdown'
import eventDefinitions from '~/events/eventDefinitions'

import _ from 'lodash'

export default {
  components: {
    Dropdown,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: 'SearchInput',
    },
  },
  data() {
    return {
      query: this.$store.state.search.dropdown.query,
      isFocused: false,
      searchDropdownComponent: null,
    }
  },
  mounted() {
    if (this.$themeSettings.components.SearchInput.enableDropdown) {
      this.$refs.dropdown.$on('init', () => {
        import('@theme/components/shop/SearchDropdown').then(component => {
          this.searchDropdownComponent = component.default
          this.$refs.dropdown.updatePosition()
        })
      })
    }
  },
  destroyed() {
    if (this.$themeSettings.components.SearchInput.clearInputAfterSubmit) {
      this.clearInput()
    }
  },
  watch: {
    autofocus: {
      handler: 'setFocus',
      immediate: true,
    },
  },
  computed: {
    tooShort() {
      return this.$store.state.search.dropdown.query.length < 3
    },
  },
  methods: {
    debounceSearch: _.debounce(
      function() {
        this.search()
      },
      300,
      { leading: false, trailing: true },
    ),
    search() {
      this.$store.dispatch('search/CHANGE_QUERY', { location: 'dropdown', query: this.query })
      this.$eventBus.$emit(eventDefinitions.SEARCH.QUERY, {
        location: 'dropdown',
        query: this.query,
      })
    },
    goToSearchResults() {
      this.blur()
      this.$router.push(this.localePath({ name: 'search', query: { s: this.query } }))
      if (this.$themeSettings.components.SearchInput.clearInputAfterSubmit) {
        this.clearInput()
      }
    },
    blur() {
      this.$refs.input.blur()
    },
    async inputFocus() {
      this.isFocused = true
      this.search()
      if (this.$themeSettings.components.SearchInput.enableDropdown) {
        this.$refs.dropdown.show()
      }
    },
    dropdownHide() {
      this.$nuxt.$emit('layoutBaseOverlayHide')
      this.$nuxt.$emit('layoutMenuOverlayHide')
    },
    dropdownShow() {
      this.$nuxt.$emit('layoutBaseOverlayShow')
      this.$nuxt.$emit('layoutMenuOverlayShow')
    },
    setFocus(value) {
      this.$nextTick(() => {
        if (this.$refs.input) {
          if (value) {
            this.$refs.input.focus()
          } else {
            this.$refs.input.blur()
          }
        }
      })
    },
    submit(e) {
      if (!this.query) {
        e.stopPropagation()
        e.preventDefault()
      }
    },
    clearInput() {
      this.query = ''
      this.$store.dispatch('search/CHANGE_QUERY', { location: 'dropdown', query: this.query })
    },
  },
}
