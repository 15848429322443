/*
* popper.js doc: https://popper.js.org/docs/v1/
*/

import ClickOutside from 'vue-click-outside'
import _ from 'lodash'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    trigger: {
      type: String,
      required: true,
    },
    type: {
      type: Array,
      default: () => ['hover'],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    delayShow: {
      type: Number,
      default: 100,
    },
    delayHide: {
      type: Number,
      default: 250,
    },
    mobileSlideDown: {
      type: Boolean,
      default: false,
    },
    hideByCloseElement: {
      type: Boolean,
      default: false,
    },
    hideOnOutsideTouch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shouldShow: false,
      initialized: false,
      open: false,
      popper: null,
      triggerObject: null,
      targetObject: null,
      dropdownObject: null,
      timeoutShow: null,
      timeoutHide: null,
      timeoutShowRunning: false,
      timeoutHideRunning: false,
    }
  },
  mounted() {
    // Get DOM objects
    this.triggerObject = document.getElementById(this.trigger)
    // Check if element exists
    if (!this.triggerObject) {
      return
    }
    this.targetObject = this.target ? document.getElementById(this.target) : this.triggerObject
    this.dropdownObject = this.$refs.dropdown

    // Check if elements exist
    if (!this.triggerObject || !this.targetObject || !this.dropdownObject) {
      return
    }

    setTimeout(() => {
      if (this.type.indexOf('click') !== -1) {
        this.triggerObject.addEventListener('click', this.show)
        this.triggerObject.addEventListener('touchstart', this.show, { passive: true })
      }
      if (this.type.indexOf('keyup') !== -1) {
        this.triggerObject.addEventListener('keyup', this.onKeyUp)
      }
      if (this.type.indexOf('hover') !== -1) {
        if (!this.type.includes('click')) {
          this.triggerObject.addEventListener('click', this.hide)
        }
        this.triggerObject.addEventListener('click', this.hide)
        this.triggerObject.addEventListener('mouseenter', this.onMouseEnter, { passive: true })
        this.dropdownObject.addEventListener('mouseenter', this.onMouseEnter, { passive: true })
        this.triggerObject.addEventListener('mouseleave', this.onMouseLeave, { passive: true })
        this.dropdownObject.addEventListener('mouseleave', this.onMouseLeave, { passive: true })
      }
      if (this.type.indexOf('focus') !== -1) {
        this.dropdownObject.addEventListener('focus', this.onMouseEnter)
        this.triggerObject.addEventListener('focus', this.onMouseEnter)
        this.dropdownObject.addEventListener('click', this.onMouseEnter) // Fixes click on dropdown while blurring input
        this.triggerObject.addEventListener('blur', this.onMouseLeave, { passive: true })
        this.dropdownObject.addEventListener('blur', this.onMouseLeave, { passive: true })
      }
    }, 500)
    if (this.hideOnOutsideTouch) {
      window.addEventListener('touchstart', e => this.clickOutside(e), { passive: true })
    }
  },
  destroyed() {
    setTimeout(() => {
      this.popper && this.popper.destroy()
      if (this.type.indexOf('click') !== -1) {
        this.triggerObject && this.triggerObject.removeEventListener('click', this.show)
        this.triggerObject && this.triggerObject.removeEventListener('touchstart', this.show)
      }
      if (this.type.indexOf('keyup') !== -1) {
        this.triggerObject && this.triggerObject.removeEventListener('keyup', this.onKeyUp)
      }
      if (this.type.indexOf('hover') !== -1) {
        if (!this.type.includes('click')) {
          this.triggerObject && this.triggerObject.removeEventListener('click', this.hide)
        }
        this.triggerObject && this.triggerObject.removeEventListener('mouseenter', this.onMouseEnter)
        this.dropdownObject && this.dropdownObject.removeEventListener('mouseenter', this.onMouseEnter)
        this.triggerObject && this.triggerObject.removeEventListener('mouseleave', this.onMouseLeave)
        this.dropdownObject && this.dropdownObject.removeEventListener('mouseleave', this.onMouseLeave)
      }
      if (this.type.indexOf('focus') !== -1) {
        this.dropdownObject && this.dropdownObject.removeEventListener('focus', this.onMouseEnter)
        this.triggerObject && this.triggerObject.removeEventListener('focus', this.onMouseEnter)
        this.triggerObject && this.triggerObject.removeEventListener('blur', this.onMouseLeave)
        this.dropdownObject && this.dropdownObject.removeEventListener('blur', this.onMouseLeave)
      }
    }, 500)
    if (this.hideOnOutsideTouch) {
      window.removeEventListener('touchstart', e => this.clickOutside(e))
    }
  },
  watch: {
    disabled(isDisabled, wasDisabled) {
      if (wasDisabled && !isDisabled) {
        this.show()
      }
    },
  },
  methods: {
    onKeyUp(event) {
      if (event.keyCode === 27) {
        this.hide()
      }
      if (event.keyCode === 13) {
        this.show()
      }
    },
    onMouseEnter() {
      this.show()
    },
    onMouseLeave() {
      this.hide()
    },
    async init() {
      if (!this.initialized) {
        this.initialized = true
        this.$emit('init')
        const Popper = await import('popper.js')

        // Set default options. These will be merged with option prop.
        const defaultOptions = {
          modifiers: {
            flip: {
              enabled: false,
            },
            preventOverflow: {
              padding: 0,
            },
          },
        }

        this.popper = new Popper.default(this.targetObject, this.dropdownObject, _.merge(defaultOptions, this.options))
      }
    },
    async show() {
      this.shouldShow = true
      if (this.disabled) return

      await this.init()

      clearTimeout(this.timeoutHide)
      this.timeoutHideRunning = false
      if (!this.timeoutShowRunning) {
        this.timeoutShowRunning = true
        this.timeoutShow = this.timeoutShow = setTimeout(() => {
          this.timeoutShowRunning = false
          this.open = true
          this.$emit('show')
          this.triggerObject.classList.add('---dropdownOpen')
          this.targetObject.classList.add('---dropdownOpen')
          this.popper?.scheduleUpdate()
        }, this.delayShow)
      }
    },
    hide() {
      if (this.popper) {
        this.shouldShow = false
        clearTimeout(this.timeoutShow)
        this.timeoutShowRunning = false
        if (!this.timeoutHideRunning) {
          this.timeoutHideRunning = true
          this.timeoutHide = setTimeout(() => {
            this.timeoutHideRunning = false
            this.open = false
            this.$emit('hide')
            this.triggerObject.classList.remove('---dropdownOpen')
            this.targetObject.classList.remove('---dropdownOpen')
            this.popper?.scheduleUpdate()
          }, this.delayHide)
        }
      }
    },
    clickOutside(event) {
      if (this.open && event.target !== this.triggerObject) {
        this.hide()
      }
    },
    updatePosition() {
      this.popper && this.popper.scheduleUpdate()
    },
  },
}
